import React from "react"
import { Helmet } from "react-helmet"
import Header from "../components/headers/header"
import Footer from "../components/footers/footer"
import Contact from "../components/contacts/contact"

/*
const fil_ariane = [
    { name: 'Contact', href: '/contact/', current: false },
    { name: 'Contactez-nous', href: '', current: true },
  ]

  <Ariane filariane={fil_ariane} />
*/
export default function Index() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Maison-fissure.fr</title>
        <meta name="description" content="Vous avez des questions. Vous cherchez un expert en bâtiment ? Contactez Maison Fissure." />
        <link rel="canonical" href="https://www.maison-fissure.fr/contact/" />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Header />
      </header>

      <main className="relative">
        <Contact />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}