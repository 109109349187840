import React from 'react'

import { Script } from 'gatsby-script'

export default function Contact() {
  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Contactez-nous</h1>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Le site maison-fissure.fr, est là pour vous guider à chaque étape du diagnostic d’une fissure. Que vous soyez propriétaire, acheteur ou vendeur, nos conseils vous permettront de comprendre pour mieux appréhender la situation et trouver la solution à votre problématique de fissure se développant sur votre maison.
            </p>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form action="https://formulaire.koudepouce.fr/edieuxexpertise/contact/" method="POST" className="grid grid-cols-1 gap-y-6">
              <div>
                <label htmlFor="nom" className="sr-only">
                  Nom
                </label>
                <input
                  type="text"
                  name="nom"
                  id="nom"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#004C8E] focus:border-[#004C8E] border-gray-300 rounded-md"
                  placeholder="Nom *"
                  required
                />
              </div>
              <div>
                <label htmlFor="prenom" className="sr-only">
                  Prénom
                </label>
                <input
                  type="text"
                  name="prenom"
                  id="prenom"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#004C8E] focus:border-[#004C8E] border-gray-300 rounded-md"
                  placeholder="Prénom"
                />
              </div>
              <div>
                <label htmlFor="cp" className="sr-only">
                  Code postal
                </label>
                <input
                  type="text"
                  name="cp"
                  id="cp"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#004C8E] focus:border-[#004C8E] border-gray-300 rounded-md"
                  placeholder="Code postal *"
                  required
                />
              </div>
              <div>
                <label htmlFor="telephone" className="sr-only">
                  Téléphone
                </label>
                <input
                  type="text"
                  name="telephone"
                  id="telephone"
                  autoComplete="tel"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#004C8E] focus:border-[#004C8E] border-gray-300 rounded-md"
                  placeholder="Téléphone *"
                  required
                />
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#004C8E] focus:border-[#004C8E] border-gray-300 rounded-md"
                  placeholder="Email *"
                  required
                />
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-[#004C8E] focus:border-[#004C8E] border border-gray-300 rounded-md"
                  placeholder="Message *"
                  defaultValue={''}
                  required
                />
              </div>

              <div>
                <div className="mt-1">
                  <input className="jCaptcha py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-[#004C8E] focus:border-[#004C8E] border-gray-300 rounded-md" type="text" placeholder="Résultat" />
                </div>
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  CGU
                </label>
                <input
                  type="checkbox"
                  id="cgu"
                  name="cgu"
                  className="inline mr-3 shadow-sm py-1 px-3 placeholder-gray-500 focus:ring-[#004C8E] focus:border-[#004C8E] border-gray-300 rounded-md"
                  required
                /> J'accepte les Conditions Générales d'Utilisation *
              </div>

              <div>
                <button
                  type="submit"
                  className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-[#004C8E] hover:bg-[#004C8E] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#004C8E]"
                >
                  Envoyer
                </button>
              </div>
            </form>
            <Script src="/jCaptcha.js" />
            <p className="mt-3 text-xs leading-6 text-gray-500">
              Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par le site Maison-fissure.fr et édité par la société Edieux Expertise SASU visant à traiter votre demande. Elles sont conservées pendant au moins 3 ans et sont destinées au service marketing et au service commercial. Nos mentions légales sont ici.<br />
              Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d’accès aux données vous concernant et les faire rectifier en contactant : Edieux Expertise SASU, Service de la protection des données, 4 Chem. des Lilas, 42800 Rive-de-Gier, ou par e-mail sur raphael.edieux[AROBASE]gmail.com en précisant dans l’objet du courrier « Droit des personnes » et en joignant la copie de votre justificatif d’identité.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
